@import url("https://fonts.googleapis.com/css2?family=Noto+Sans");
body {
  margin: 0;
  font-family: "Noto Sans";
  background: white;
  color: black;
  height: 100%;
}
h1,
h2,
h3 {
  color: black;
  /* 
	-webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white; */
  font-weight: 600;
}
.projects {
  display: flex;
  justify-content: center;

  width: 100vw;
}
.project {
  width: 300px;
  height: 200px;
  background: rgb(231, 231, 231);
  display: block;
  margin: 15px;

  border-radius: 10px;
}
.project h2 {
  margin-top: 20px;
  margin-left: 10px;
  color: black;
}
.back {
  width: 280px;
  height: 140px;
  background-position: center;
  background-size: cover;
  position: relative;
  opacity: 0.8;
  border-radius: 10px;
}
.showcase iframe {
  width: 100vw;
  height: 70vh;
}
