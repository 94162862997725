@tailwind base;
@tailwind components;
@tailwind utilities;

/* Roboto */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
/* body {
  margin: 0;
}
a {
  text-decoration: none;
} */
